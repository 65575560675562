import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {TabBar} from 'antd-mobile'
import {HomeOutlined, LineChartOutlined, MenuOutlined, PieChartOutlined} from '@ant-design/icons'

interface props {
    content?: React.ReactNode,
}

const Layout: React.FC<props> = ({content}) => {
    const navigate = useNavigate()
    const location = useLocation()

    const tabs = [
        {
            key: '/',
            title: 'Home',
            icon: <HomeOutlined className='text-lg'/>,
        },
        {
            key: '/invest',
            title: 'Invest',
            icon: <LineChartOutlined className='text-lg'/>,
        },
        {
            key: '/portfolio',
            title: 'Portfolio',
            icon: <PieChartOutlined className='text-lg'/>,
        },
        {
            key: '/more',
            title: 'More',
            icon: <MenuOutlined className='text-lg'/>,
        },
    ]

    const handleTabChange = (key: string) => {
        navigate(key)
    }

    return (
        <>
            <div className='flex flex-col' style={{maxWidth: 450, margin: 'auto'}}>
                {content}
                <div className='border-y-2 fixed bottom-0 right-0 left-0 z-10 bg-white border-b-2'>
                    <TabBar onChange={handleTabChange} activeKey={location.pathname}>
                        {tabs.map(item => (
                            <TabBar.Item key={item.key} icon={item.icon} title={item.title}/>
                        ))}
                    </TabBar>
                </div>
            </div>
        </>
    )
}

export default Layout
